// Gatsby supports TypeScript natively!
// @ts-ignore
import React, { useState } from "react"
import { PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
// @ts-ignore
import YAMLData from "../../glossary/glossary.yml"

const sorted = ["A-Z", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
const glossary = YAMLData.map(element => ({ word: element.word }));
const Glossary = (props: PageProps) => {
  const [selected, setSelected] = useState("A-Z");
  return (
    <Layout>
      <SEO title="Glossary" />
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <div>
        {sorted.map(item => (
          <React.Fragment key={item}>
            <div
              key={item}
              onClick={() => setSelected(item)}
              style={{
                display: "inline-block",
                fontSize: "1rem",
                fontWeight: "bold",
                cursor: "pointer",
                lineHeight: 0,
              }}
            >
              {item}
            </div>
            {item == "Z" ? null : (
              <svg width="1.7rem" height="0.7rem">
                <circle cx="0.85rem" cy="0.35rem" r="0.1rem" />
              </svg>
            )}
          </React.Fragment>
        ))}
        </div>
      </div>
      <hr style={{ marginTop: "1rem", borderTop: "0.01rem solid black" }} />
      <div>
        <p>Words, words, words for {selected}</p>
      </div>
      <ul>
        {glossary
          .filter(item => {
            if (!selected) return true;
            // if (selected === "0-9") {
            //   return ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(item[0]);
            // }
            if (selected === "A-Z") {
              // return !["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(item[0]);
              return true;
            }

            return item.word[0].toLowerCase() === selected.toLowerCase();
          })
          .map(item => (
          <li key={item.word}>
            {item.word}
          </li>
        ))}
      </ul>
    </Layout>
  )
}

export default Glossary
